/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lora', serif;
}

/* Home Content Container */
.home-content {
    margin: 0 auto;
    text-align: center;
    color: #1B263B;
    line-height: 1.6;
}

/* Header Container */
.header-container {
    position: relative;
    display: flex;
    align-items: center; 
    justify-content: center;
    margin-bottom: 30px;
    margin-top: 150px;
}

/* Header Text */
.header-text {

    text-align: left;
    color: #1B263B;
    margin-right: 60px;
}

.header-container h1 {
    font-size: 122px;
    height:auto;
    font-weight: bold;
    color: black; 
    border-bottom: 2px solid black; /* Adjust thickness and color */
    line-height: 1.0;

}

.header-container h2 {
    font-size: 32px;
    color: black; 
}

/* Headshot Image */
.headshot {
    width: 300px; /* Set width */
    height: 300px; /* Set height equal to width */
    border-radius: 50%; /* Make the image circular */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    object-fit: cover; /* Ensure the image scales properly */
    object-position: 50% 25%; /* Adjust the visible portion of the image */

}

.affliation h3 {
    font-size: 22px;
}


.consultation-box {
    position: relative;
    display: flex;
    align-items: center; 
    justify-content: center;
    margin-top: 125px; /* Space between the overlay-text and the consultation-box */
    margin-bottom: 75px ;

}

.consultation-link {
    color: #f9f9f9; /* Matches the text color of overlay-text */
    font-size: 26px; /* Matches the paragraph font size of overlay-text */
    font-weight: bold; /* Ensures the text stands out */
    text-decoration: none; /* Removes underline from the link */
    display: inline-block; /* Allows padding to be applied */
    padding: 10px 20px; /* Adds some padding around the text for better visibility */
    border-radius: 5px; /* Optional: adds a slight border radius for a button-like appearance */
    background-color: black; /* Optional: slightly lighter background for the link */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.consultation-link:hover {
    background-color: grey; /* Lightens the background on hover */
}




@media (max-width: 768px) {
    /* Adjust header layout */
    .header-container {
        flex-direction: column; /* Stack the elements vertically */
        align-items: center; /* Center the content */
        text-align: center; /* Center text */
        margin-top: 50px;
    }

    .header-text {
        text-align: center;
        color: #1B263B;
        margin: 0;
        padding-left: 80px;
        padding-right: 80px;
    }

    .header-container h1 {
        font-size: 78px; /* Smaller font size */
        height: auto; /* Let height adjust naturally */
        width: auto; /* Remove fixed width */
        border-bottom: 2px solid black; /* Adjust thickness and color */
        line-height: 1.2;
    }

    .header-container h2 {
        font-size: 28px; /* Smaller font size */
        line-height: 1.4; /* Improve readability */
        margin-top: 10px; /* Add spacing above h2 */
    }

    /* Adjust headshot image */
    .headshot {
        order: -1;
        width: 250px; /* Reduce image size */
        height: 250px; /* Match reduced width */
        margin: 40px 0; /* Add spacing around image */
    }

    /* Adjust content container */
    .home-content {
        padding: 0 20px; /* Add some horizontal padding */
    }

    .consultation-box {
        display:flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 20px;
        margin-top: 50px; /* Space between the overlay-text and the consultation-box */

    }

    .consultation-link {
        color: white; /* Matches the text color of overlay-text */
        font-size: 26px; /* Matches the paragraph font size of overlay-text */
        font-weight: bold; /* Ensures the text stands out */
        text-decoration: none; /* Removes underline from the link */
        display: inline-block; /* Allows padding to be applied */
        padding: 10px 20px; /* Adds some padding around the text for better visibility */
        border-radius: 5px; /* Optional: adds a slight border radius for a button-like appearance */
        background-color: black; /* Optional: slightly lighter background for the link */
        transition: background-color 0.3s ease; /* Smooth transition for hover effect */
    }

    .consultation-link:hover {
        background-color: grey; /* Lightens the background on hover */
    }
}