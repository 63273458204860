/* Outer Wrapper */
.toggle-wrapper {
  background-color: #f8f9fa; /* Offset background */
  padding: 40px;
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
}

/* Inner Container */
.toggle-container {
  width: 970px; /* Fixed width */
  max-width: 90%; /* Allows it to shrink on smaller screens */
  padding: 40px 20px;
  text-align: center;
  background-color: #faf3e0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Header */
.toggle-header {
  font-size: 74px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  border-bottom: 3px solid;
  display: inline-block;
  padding-bottom: 5px;
}

/* Toggle Buttons */
.toggle-buttons {
  display: flex;
  justify-content: center;
  gap: 60px;
  margin-bottom: 60px;
}

.toggle-buttons button {
  padding: 12px 24px;
  font-size: 36px;
  border: 2px solid black;
  background-color: white;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  font-weight: bold;
}

.toggle-buttons button:hover {
  background-color: black;
  color: white;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
}

.toggle-buttons button.active {
  background-color: black;
  color: white;
}

/* Content Sections */
.sold-section, .testimonials-section {
  margin-top: 20px;
  padding: 20px;
}

.toggle-container h2 {
  font-size: 66px;
  margin-bottom: 30px;
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 17px;
}

/* Recently Sold */
.recently-sold-slide {
  text-align: center;
}

.home-image {
  width: 90%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.home-address {
  font-size: 32px;
  font-weight: bold;
  margin-top: 15px;
}

.home-price {
  font-size: 28px;
  color: #555;
  margin-top: 5px;
  margin-bottom: 15px;
}

/* Testimonials */
.testimonial-slide {
  display: none;
  font-size: 18px;
  line-height: 1.6;
  color: #555;
}

.testimonial-slide.active {
  display: block;
}

.testimonial-slide p {
  font-size: 32px;
  line-height: 1.6;
  color: #333;
  margin-bottom: 10px;
}

.testimonial-slide h3 {
  margin-top: 10px;
  font-size: 36px;
  color: black;
}

/* Navigation Buttons */
.prev-btn, .next-btn {
  background-color: black;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 18px;
  margin: 20px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.prev-btn:hover, .next-btn:hover {
  background-color: grey;
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .toggle-container {
    padding: 20px 10px;
  }

  .toggle-header {
    font-size: 52px;
  }

  .toggle-container h2 {
    font-size: 46px;
    margin-bottom: 40px;
    text-underline-offset: 12px;

  }

.toggle-buttons {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  gap: 30px;
  margin-bottom: 30px;
}

.toggle-buttons button {
  padding: 8px 8px;
  font-size: 22px;
  border: 2px solid black;
  background-color: white;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  font-weight: bold;
}

  .sold-section, .testimonials-section {
    padding: 15px;
  }

  .home-image {
    margin-bottom: 20px;
  }

  .home-address {
    font-size: 24px;
  }

  .home-price {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .testimonial-slide h3 {
  font-size: 26px;
}

  .testimonial-slide p {
    font-size: 25px;
  }

  .prev-btn, .next-btn {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
}
