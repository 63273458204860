
.App-logo {
  height: 45vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  background-color: #faf3e0; /* light tan color */
  color: #333; /* White text */
  margin: 0;
  font-family: 'Lora', sans-serif;
}

.App-link {
  color: #61dafb;
}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
