.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup-content {
  background: white;
  padding: 20px;
  width: 600px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.popup-content h2 {
  font-size: 48px;
  margin-bottom: 10px;
}

.popup-content p {
  font-size: 26px;
  font-weight: bold;
  color: #555;
}

.popup-content input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  font-size: 26px;
  font-weight:bold;
}


.popup-content button {
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 0px;
}

.popup-content button:hover {
  background-color: grey;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  background: none;
  border: none;
  font-size: 15px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .popup-content {
    width: 90%; /* Ensures it doesn't take up full width */
    padding: 20px;
  }

  .popup-content h2 {
    font-size: 28px;
  }

  .popup-content p {
    font-size: 18px;
  }

  .popup-content input {
    font-size: 16px;
    padding: 10px;
  }

  .popup-content button {
    font-size: 22px;
    padding: 5px;
  }

  /* Right now the close button is being overwritten by the popup-content button*/
  .popup-content close-btn {
    font-size: 14px;
    top:5px;
    right: 5px;
    padding: 5px;
  }
}