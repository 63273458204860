.App-footer {
  display: flex;
  justify-content: center;
  width: 100%; /* Ensure full width */
  background-color: #faf3e0;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 40px;
  padding-top: 20px;

}

.subscription-container {
/*  background-color: #fcfaf7;*/
}